import { render, staticRenderFns } from "./Shipment.vue?vue&type=template&id=44b50882&scoped=true&"
import script from "./Shipment.vue?vue&type=script&lang=ts&"
export * from "./Shipment.vue?vue&type=script&lang=ts&"
import style0 from "./Shipment.vue?vue&type=style&index=0&id=44b50882&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b50882",
  null
  
)

export default component.exports