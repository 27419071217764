


















import { Component, Prop, Mixins } from "vue-property-decorator";
import OrderStatusesMixin from "@/mixins/http/OrderStatusesMixin";
import InlineMultiSelect from "@/components/InlineMultiSelect.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import OrdersMixin from "@/mixins/http/OrdersMixin";
import { OrderStatus } from "@/models";

const tag = "get_order_statuses";
const statsTag = "get_stats";

@Component({ components: { InlineMultiSelect } })
export default class OrderStatusMultiselect extends Mixins(
  OrderStatusesMixin,
  OrdersMixin
) {
  @Prop() idSelected!: string | number;
  @Prop({ default: (): any[] => [] }) value: any[] | null;
  @Prop() params!: any;
  @Prop() formClass: string;
  @Prop() searchEnabled: boolean;
  @Prop() classes: string;
  @Prop() dataKeys?: Array<string>;
  @Prop() placeholder?: string;
  @Prop({ default: false }) actsLikeFilter?: boolean;
  @Prop({ required: false }) queryKey: string;
  @Prop({ default: true }) preselect?: boolean;
  @Prop() type: string;

  private items: any = [];
  private id: any = this.idSelected || null;

  private filter = { ...this.params };

  @RegisterHttp(tag) getItemsTag: RequestData;

  @RegisterHttp(statsTag) statsRequest: RequestData;

  get queryParams() {
    return { ...this.params };
  }

  mounted() {
    this.loadItems();
  }

  onSearch(term: string) {
    this.filter.term = term;
    this.loadItems();
  }

  onDataUpdated(data: OrderStatus) {
    this.$emit("change", data);
  }

  onItemSelected(data: OrderStatus) {
    this.$emit("change", data);
  }

  async getStats() {
    const counters = await this.getOrderStats(
      { sourceId: this.filter.source },
      statsTag
    );
    return counters;
  }

  async loadItems() {
    const data = { filter: { ...this.filter } };
    const items = await this.getOrderStatuses(data, tag);

    let counters = {};
    try {
      counters = await this.getStats();
    } catch (e) {
      console.log(e)
    }
    this.items = items.map((o: OrderStatus) => {
      return { id: o.id, name: o.name, count: (counters as any)[parseInt(o.id)] };
    });
  }

  onItemsReady() {
    if (this.idSelected) {
      this.id = this.idSelected;
    } else {
      if (this.preselect) {
        this.id =
          this.queryKey && this.$route.query[this.queryKey]
            ? this.$route.query[this.queryKey]
            : this.items[0]?.id;
      }
    }
  }
}
