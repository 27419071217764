











































































































import { Order, Source } from "@/models";
import {  Component, Mixins, Watch } from "vue-property-decorator";
import OrdersMixin from "@/mixins/http/OrdersMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import Preview from "@/components/Order/Preview.vue";
import EmptyView from "@/components/EmptyView.vue";
import OrderStatusMultiSelect from "@/components/OrderStatus/MultiSelect.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import OrderItem from "@/components/Order/ListItem.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import Search from "@/components/VEntrySearch.vue";
import Throttler from "@/utils/Throttler";
import OrderImporter from "@/components/Order/Importer.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import DatePicker, { DatePickerData } from '@/components/DatePicker.vue';
import OrderTypeMultiSelect from '@/components/Order/TypeMultiSelect.vue';

import moment from 'moment';
import { sourceStore } from "@/store/typed";

const getOrdersTag = "get_orders";

@Component({
  components: {
    DataTable,
    OrderImporter,
    EmptyView,
    OrderItem,
    RoundThumb,
    Search,
    Preview,
    OrderStatusMultiSelect,
    PreviewModal,
    VPageContentSticky,
    DatePicker,
    OrderTypeMultiSelect
  },
})
export default class OrderList extends Mixins(OrdersMixin) {
  @RegisterHttp(getOrdersTag) getOrdersRequest: RequestData;

  items: Order[] = [];
  private throttler = new Throttler(500);
  page = 1;
  private search: string | null = null;

  filter: any = { orderStatus: [], date_from: '', date_to: '', type: [] };
  private dateFrom = '';
  private dateTo = '';

  columns = [
    { name: "externalId", label: "Codice" },
    { name: "createdAt", label: "Creato il" },
    { name: "status", label: "Stato" },
    { name: "order_lines", label: "Articoli" },
    { name: "customer", label: "Cliente" },
    { name: "total", label: "Totale" },
  ];

  created() {
    this.feedFiltersFromParams();
    this.subscribe();
  }

  get source() {
    return sourceStore.currentSource;
  }

  get lastDate() {
    return moment()
      .add(1, 'days')
      .format('YYYY-MM-DD');
  }

  onOrderStatusesSelected(payload: { orderStatusIds: string[] }) {
    this.filter.orderStatus = payload.orderStatusIds;

    this.throttler.run(() => {
      if(this.page!=1) {
        this.page = 1;
      } else {
        this.loadItems();
      }
    })
  }

  private onOrderTypeSelected(selected: string[]) {
    this.filter.type = selected;

    this.throttler.run(() => {
      if(this.page!=1) {
        this.page = 1;
      } else {
        this.loadItems();
      }
    })
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {

    if(value>this.meta?.lastPage+1) {
      return;
    }
    if (value == 1 && oldValue) {
      this.items = [];
    }
    if (oldValue) {
      this.loadItems();
    }
  }

  mounted() {
    if (this.source) {
      this.loadItems();
    }
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;

      const [data, errors] = await easync(this.getOrders(this.payload, getOrdersTag));
      if (errors) {
        showToast("Si è verificato un errore", { type: "error"  });
        return;
      }

      this.updateQueryParams();
      return data;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: {items: Order[]}) {
    this.page = 1;
    this.items = items.items;
  }

  onDateSelected(date: DatePickerData) {
    this.filter.date_from = date.range.from.toString();
    this.filter.date_to = date.range.to.toString();

    this.page = 1;
    this.loadItems();
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    this.items = [];
    this.page = 1;
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    if(!this.loading){
      this.page += 1;
    }
  }

  updateQueryParams() {
    let params: any = {};

    if (this.page) {
      params.page = this.page;
    }

    if (this.search) {
      params.search = this.search;
    }

    if (this.filter.date_from && this.filter.date_to) {
      params.dateFrom = this.filter.date_from;
      params.dateTo = this.filter.date_to;
    }

    if (this.filter.orderStatus.length) {
      params.orderStatus = this.filter.orderStatus;
    }

    this.$router.replace({
      path: this.$route.path,
      query: params
    }).catch((e: any) => console.log('Navigation duplicated'));
  }

  feedFiltersFromParams() {
    const params = this.$route.query;

    if (params.page) {
      this.page = parseInt(params.page as string);
    }

    if (params.search) {
      this.search = params.search as string;
    }

    if (params.dateFrom && params.dateTo) {
      this.filter.date_from = params.dateFrom as string;
      this.filter.date_to = params.dateTo as string;
    }
  }

  resetFilters() {
    this.page = 1;
    this.filter.date_from = '';
    this.filter.date_to = '';
    this.search = '';

    this.loadItems();
  }

  get payload() {
    const data: any = {
      per: 20,
      page: this.page,
      filter: {
        ...this.filter,
        source: this.source?.id,
      },
    };
    if (this.search) {
      data.filter.term = this.search;
    }
    return data;
  }

  async loadItems() {
    if (this.getOrdersRequest?.loading) {
      return;
    }

    const [data, errors] = await easync(this.getOrders(this.payload, getOrdersTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
    this.updateQueryParams();
  }

  get loading() {
    return this.getOrdersRequest?.loading;
  }

  get newLoading() {
    return this.loading && this.page === 1;
  }

  get meta() {
    return this.getOrdersRequest?.meta;
  }
}
