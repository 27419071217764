




















































import { WoocommerceCustomer, Product,Coupon } from '@/models';
import { Carousel, Slide } from 'vue-carousel';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class CouponSourceData extends Vue {
  @Prop() item: Coupon;

  get sourceType() {
    if (!this.item) {
      return "";
    }
   if (this.item.constructor.name.toLowerCase().includes('woocommerce')) {
      return 'Woocommerce';
    } else{
      return "";
    }
  }
}
