





































































































import Search from "@/components/VEntrySearch.vue";
import { Source } from "@/models";
import { Component, Mixins, Watch } from "vue-property-decorator";
import ProductsMixin from "@/mixins/http/ProductsMixin";
import DownloadsMixin from "@/mixins/http/DownloadsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import Preview from "@/components/Order/Preview.vue";
import { Product } from "@/models";
import EmptyView from "@/components/EmptyView.vue";
import IncompleteProducts from "@/components/Product/IncompleteList.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getProductsTag = "get_products";

@Component({
  components: {
    DataTable,
    RoundThumb,
    Preview,
    Search,
    DownloadsMixin,
    EmptyView,
    VPageContentSticky,
    IncompleteProducts,
  },
})
export default class ProductList extends Mixins(ProductsMixin, DownloadsMixin) {
  @RegisterHttp(getProductsTag) getProductsRequest: RequestData;

  items: Product[] = [];
  page = 1;
  private search: string | null = null;
  private exporting = false;

  columns = [
    { name: "approved", label: "Approvato" },
    { name: "name", label: "Nome" },
    { name: "sku", label: "Codice" },
    { name: "price", label: "Prezzo" },
    { name: "createdAt", label: "Creato il" },
  ];

  created() {
    this.subscribe();
  }

  onSelected(item: Product) {
    this.$router.push({ name: "products_show", params: { id: item.id } });
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value > this.meta?.lastPage + 1) {
      return;
    }
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  get source() {
    return sourceStore.currentSource;
  }

  mounted() {
    if (this.source) {
      this.loadItems();
    }
  }

  async dumpProducts() {
    this.exporting = true;
    const [data, errors] = await easync(
      this.exportProducts({ source: this.source?.id })
    );
    this.exporting = false;
    if (errors) {
      showToast("Sì è verificato un errore durante l'export", {
        type: "error",
      });
    }
  }

  openConfigurationDashboard() {
    this.$router.push({ name: "products_import_dashboard" });
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getProducts(this.payload, getProductsTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: { items: Product[] }) {
    this.page = 1;
    this.items = items.items;
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: this.source?.id,
        term: this.search,
      },
    };
    return data;
  }

  async loadItems() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: this.source?.id,
      },
    };

    const [res, errors] = await easync(
      this.getProducts(this.payload, getProductsTag)
    );
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...res);
  }

  get loading() {
    return this.getProductsRequest?.loading;
  }

  get meta() {
    return this.getProductsRequest?.meta;
  }
}
