











































import { Order, Source } from "@/models";
import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import EmptyView from "@/components/EmptyView.vue";
import CouponsMixin from "@/mixins/http/CouponsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import Preview from "@/components/Order/Preview.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import { Coupon } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const getCouponsTag = "get_coupons";

@Component({
  components: { DataTable, RoundThumb, Preview, PreviewModal, EmptyView }
})
export default class CouponList extends Mixins(CouponsMixin) {
  @RegisterHttp(getCouponsTag) getCouponsRequest: RequestData;

  items: Coupon[] = [];
  page = 1;

  columns = [
    { name: "code", label: "Codice" },
    { name: "discountType", label: "Tipo di sconto" },
    { name: "amount", label: "Valore" }
  ];

  onSelected(item: Coupon) {
    this.$router.push({ name: "coupons_show", params: { id: item.id } });
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  async loadItems() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id
      }
    };

    const [res, errors] = await easync(this.getCoupons(data, getCouponsTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...res);
  }

  get loading() {
    return this.getCouponsRequest?.loading;
  }

  get meta() {
    return this.getCouponsRequest?.meta;
  }
}
