





































































import { Order, OrderStatus, Source } from "@/models";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import OrdersMixin from "@/mixins/http/OrdersMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import RoundThumb from "@/components/RoundThumb.vue";
import Preview from "@/components/Order/Preview.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import OrderLines from "@/components/OrderLine/List.vue";
import { showErrorToast, showToast } from "@/utils/Toast";
import SourceData from "@/components/Product/SourceData.vue";
import WarehouseData from "@/components/Product/WarehouseData.vue";
import PrintData from "@/components/Product/PrintData.vue";
import OrderSummary from "@/components/Order/Summary.vue";
import OrderShipment from "@/components/Order/Shipment.vue";
import OrderDesigns from "@/components/Order/Designs.vue";
import OrderCoupons from "@/components/Order/Coupons.vue";
import OrderDebug from "@/components/Order/Debug.vue";
import ConfirmModal from '@/components/ConfirmModal.vue';
import VStatusSelect, { VStatusSelectOption } from '@/components/VStatusSelect.vue';
import { User } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "../../store/typed";
import OrderStatusesMixin from "@/mixins/http/OrderStatusesMixin";
import {PRIMARY_COLOR} from "@/utils/Constants";

const getItemTag = "get_order";
const updateItemTag = "update_order";
const getOrderStatuses = "get_order_statuses";
export const generateLabelTag = "generate_label";

@Component({
  components: {
    RoundThumb,
    SourceData,
    OrderCoupons,
    OrderSummary,
    OrderDesigns,
    PrintData,
    WarehouseData,
    Preview,
    OrderLines,
    OrderDebug,
    PreviewModal,
    OrderShipment,
    ConfirmModal,
    VStatusSelect
  }
})
export default class OrderShow extends Mixins(OrdersMixin, OrderStatusesMixin) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(updateItemTag) updateItemRequest: RequestData;
  @RegisterHttp(generateLabelTag) generateLabelRequest: RequestData;

  @Getter currentUser: User;

  item: Order = null;
  private statusOptions: VStatusSelectOption[] = [];

  @Ref() readonly confirmModal!: ConfirmModal;

  mounted() {
    this.load();
    this.orderStatusOptions();
  }

  async generateLabel() {
    const payload = { id: this.item.id };

    const [data, errors] = await easync(
      this.generateOrderLabel(payload, generateLabelTag)
    );
    if (errors) {
      showToast("Errore nell'aggiornamento dell'ordine", { type: "error" });
      return;
    }

    this.item.labelUrl = data.labelUrl;
    showToast("Etichetta aggiornata!");
  }

  get id() {
    return parseInt(this.$route.params.id);
  }

  async update(data: any = null) {
    const payload = { id: this.item.id, ...data };

    const [res, errors] = await easync(
      this.updateOrder(payload, updateItemTag)
    );
    if (errors) {
      showToast("Errore nell'aggiornamento dell'ordine", { type: "error" });
      return;
    }

    this.item = res;
    showToast("Ordine aggiornato!");
  }

  async load() {
    if (!this.id) {
      return;
    }
    const [data, errors] = await easync(this.getOrder(this.id, getItemTag));
    if (errors) {
      showToast("Si è verificato un errore", { type: "error" });
      return;
    }

    this.item = data;
  }

  private async onDeleteRequest() {
    const message = "Sei sicuro di voler eliminare questo ordine?";
    const confirmed = await (this.confirmModal as any).confirm(message);

    if (confirmed) {
      this.deleteOrder();
    }
  }

  private async deleteOrder() {
    const [data, errors] = await easync(this.destroyOrder(this.item.id, 'order_destroy'));

    if (errors) {
      showErrorToast('Si è verificato un errore durante l\'eliminazione dell\'ordine');
      return;
    }

    showToast('Cancellazione avvenuta con successo!');
    this.$router.push({ name: 'orders_list' });
  }

  get loading() {
    return this.getItemRequest?.loading;
  }

  get meta() {
    return this.getItemRequest?.meta;
  }

  private async orderStatusOptions() {
    const [data, errors] = await easync(this.getOrderStatuses({filter: {source: sourceStore.currentSource.id}}, getOrderStatuses));

    if (data) {
      this.statusOptions = data.map((el: OrderStatus) => { return { id: el.id, name: el.name, color: el.color||PRIMARY_COLOR } });
    } else {
      this.statusOptions = [];
    }
  }

  private async updateStatus(newStatus: VStatusSelectOption) {
    const payload = { id: this.item.id, orderStatusId: newStatus.id };

    const [res, errors] = await easync(
      this.updateOrder(payload, updateItemTag)
    );
    if (errors) {
      showToast("Errore nell'aggiornamento dell'ordine", { type: "error" });
      return;
    }

    this.item = res;
    showToast("Ordine aggiornato!");
  }
}
