


































































































































import { RequestData } from "@/store/types/RequestStore";
import { generateLabelTag } from "@/views/Order/Show.vue";
import { Order } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
const http = namespace("http");

@Component({})
export default class OrderSummary extends Vue {
  @Prop() readonly value!: Order;

  private showBilling = true;

  @http.Getter status: (tag: string) => RequestData;

  get fullAddress() {
    const address = this.showBilling ? this.value.billing : this.value.shipping;
    return `${address.address1} ${address.postcode}, ${address.city} (${address.state}) ${address.country}`;
  }

  generateLabel() {
    this.$emit("generateLabel");
  }

  get generating() {
    return (this.status as any)[generateLabelTag]?.loading;
  }

  get transactionId() {
    return this.value.rawData?.transactionId;
  }
}
