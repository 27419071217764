

















import { Vue, Component } from "vue-property-decorator";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import FullImport from "@/components/Product/FullImport.vue";
import SinglePrintFileImport from "@/components/Product/SinglePrintFileImport.vue";

@Component({ components: { VPageContentSticky, SinglePrintFileImport,FullImport } })
export default class ConfigurationDashboard extends Vue {}
