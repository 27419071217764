






























































































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { Customer, Order, Source } from "@/models";
import OrdersMixins from "@/mixins/http/OrdersMixin";
import { easync } from "@/utils/http";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import VPaginator from "@/components/VPaginator.vue";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";

const listTag = "index_orders";

@Component({ components: { VPaginator, DataTable } })
export default class CutomerOrders extends Mixins(OrdersMixins) {
  @Prop({ required: true }) readonly customer!: Customer;

  @RegisterHttp(listTag) getOrdersRequest!: RequestData;

  private orders: Order[] = [];

  mounted() {
    this.loadOrders();
  }

  get columns() {
    return [
      { name: "external_id", label: "ID" },
      { name: "created_at", label: "Creato il" },
      { name: "status", label: "Stato" },
      { name: "order_lines", label: "Articoli" },
      { name: "total", label: "Totale" },
    ];
  }

  onSelected(o: Order) {
    this.$router.push({ name: "orders_show", params: { id: o.id } });
  }

  get loading() {
    return this.getOrdersRequest?.loading;
  }

  statusStyle(order: Order) {
    const color = (order.orderStatus as any).color;
    if (color) {
      return {
        "border-color": color,
        color: color,
      };
    } else {
      return {};
    }
  }

  get meta() {
    return this.getOrdersRequest?.meta;
  }

  async loadOrders(page = 1) {
    const payload = {
      filter: {
        customer: this.customer.id,
      },
      page: page,
    };

    const [data, errors] = await easync(this.getOrders(payload, listTag));

    if (data) {
      this.orders = data;
    }
  }
}
