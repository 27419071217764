


























import { Order } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";
import PrintSideSelect from "@/components/PrintSide/Select.vue"
import { Design } from "@/models";

@Component({components: { PrintSideSelect}})
export default class Designs extends Vue {
  @Prop({ required: true }) readonly value!: Order;

  get designs(): Array<Design> {
    let designs: Array<Design> = [];
     this.value.orderLines.forEach(element => {
       designs.push(...element.designs)
    });
    return designs;
  }

  onPrintSideSelected(id: string, design: Design){
    design.printSideId = id;
  }
}
