





















































































import { Component, Mixins } from "vue-property-decorator";
import Accordion from "@/components/VAccordion.vue";
import FileUploader from "@/components/FileUploader.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import ProductsMixin from "@/mixins/http/ProductsMixin";
const UPLOAD_SINGLE_PRINT_FILE = "UPLOAD_SINGLE_PRINT_FILE";
import { namespace } from "vuex-class";
import { Source } from "@/models";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

@Component({
  components: {
    Accordion,
    FileUploader,
  },
})
export default class SinglePrintFileImport extends Mixins(ProductsMixin) {
  @RegisterHttp(UPLOAD_SINGLE_PRINT_FILE) uploadRequest: RequestData;

  private quantity = 1;
  private overwrite = false;
  private files: any = [];
  private updatedProducts: any = [];

  reset() {
    this.quantity = 1;
    this.overwrite = false;
    this.files = [];
    this.updatedProducts = [];
  }

  get loading() {
    return this.uploadRequest?.loading;
  }

  async upload() {
    let formdata = new FormData();
    formdata.append("quantity", this.quantity.toString());
    formdata.append("source_id", sourceStore.currentSource.id);
    formdata.append("overwrite", this.overwrite ? "1" : "0");
    for (let file of this.files) {
      formdata.append("files[]", file.file);
    }
    this.updatedProducts = await this.uploadPrintFile(
      formdata,
      UPLOAD_SINGLE_PRINT_FILE
    );
    showToast("Import completato");
  }
}
