


































































































































































import {
  Vue,
  Component,
  Prop,
  Mixins,
  Watch,
  Ref,
} from "vue-property-decorator";
import ProductLabelsMixin from "@/mixins/http/ProductLabelsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { ProductLabelPayload } from "@/models/ProductLabel";
import FileUploader from "@/components/FileUploader.vue";
import ColorPicker from "@/components/VColorPicker.vue";
import { ProductLabel } from "@/models";
import { showErrorToast, showToast } from "@/utils/Toast";
import FieldForm from "@/components/ProductLabel/FieldForm.vue";
import { ProductLabelField, ProductLabelMetadata } from "@/models/ProductLabel";
import { Product } from "@/models";
import { namespace } from "vuex-class";
import { easync } from "@/utils/http";

const tag = "create_print_line";
const destroyTag = "destroy_print_line";

@Component({
  components: {
    FileUploader,
    ColorPicker,
    FieldForm,
  },
})
export default class ProductLabelEdit extends Mixins(ProductLabelsMixin) {
  @Prop() readonly label!: ProductLabel;

  @Prop({ default: "md" }) size: string;

  private item: ProductLabelPayload = ProductLabelPayload.fromData(this.label);

  @Ref() readonly fieldForm!: FieldForm;

  @RegisterHttp(tag) readonly createRequest!: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;

  get url() {
    return this.file?.url || this.item?.url;
  }

  get cutUrl() {
    return this.cutFile?.url || this.item?.cutUrl;
  }

  showForm = false;
  file: any = null;
  cutFile: any = null;
  selectedIndex: number | null = null;

  get imageClass() {
    return [`product-label-image product-label-image-${this.size}`];
  }

  editField(field: ProductLabelField, index: number) {
    this.fieldForm.edit(field);
    this.selectedIndex = index;
  }

  onDestroy(index: number) {
    this.item.metadata.fields.splice(index, 1);
  }

  createField() {
    this.fieldForm.create();
  }

  typeIcon(type: "text" | "image") {
    return type === "image" ? "image" : "heading";
  }

  columnLabel(column: string) {
    return Product.selectableColumns.find((o) => column === o.id)?.label;
  }

  get fields() {
    return this.item.metadata.fields;
  }

  onUpdated(item: ProductLabelField) {
    this.$set(this.item.metadata.fields, this.selectedIndex, item);
    this.selectedIndex = null;
  }
  onCreated(field: ProductLabelField) {
    this.item.metadata.fields.push(field);
  }

  get isDynamic() {
    return this.item?.kind === "dynamic";
  }

  get formClass() {
    return [`product-label-form-${this.size}`];
  }

  @Watch("showForm")
  onShowFormChanged(value: boolean) {
    if (value) {
      this.item = ProductLabelPayload.fromData(this.label);
    } else {
      this.item = null;
    }
  }

  async create() {
    let formData = new FormData();

    formData.append("quantity", this.item.quantity.toString());
    formData.append("metadata", JSON.stringify(this.item.metadata as any));

    if (this.file) {
      formData.append("image", this.file.file);
    }
    if (this.cutFile) {
      formData.append("cut", this.cutFile.file);
    }

    const [data, errors] = await easync(this.updateProductLabel(this.item.id, formData, tag));
    if (errors) {
      showErrorToast();
      return;
    }

    this.$emit("updated", data);
    this.item = ProductLabelPayload.fromData(data);
    this.showForm = false;
    showToast("Etichetta aggiornata!");
  }

  async destroy() {
    const [data, errors] = await easync(this.destroyProductLabel(this.item.id, destroyTag));
    if (errors) {
      showErrorToast();
      return;
    }

    this.$emit("destroyed", data);
    this.showForm = false;
    this.item = data;
    showToast("Etichetta eliminata!");
  }

  get loading() {
    return (
      this.createRequest?.loading ||
      this.destroyRequest?.loading
    );
  }
}
