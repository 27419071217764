


























































import {Component, Prop, Mixins } from "vue-property-decorator";
import ShipmentsMixin from "@/mixins/http/ShipmentsMixin";
import { Order } from "@/models";
import { capitalize } from "@/utils/Typography";

const GET_SHIPMENT = "get_shipment";
@Component({})
export default class OrderShipment extends Mixins(ShipmentsMixin) {
  @Prop({ required: true }) readonly value: Order;

  get shipment() {
    return this.value.shipment;
  }
  formatMessage(message: string) {
    return capitalize(message.toLowerCase());
  }

  icon(message: string) {
    if(message.toLowerCase().includes('consegnat')) {
      return 'check-circle'
    } else if(message.toLowerCase().includes('registrat')) {
      return 'parcel'
    }
    return 'truck'
  }
}
