





































import { Order, Source } from "@/models";

import {
  Vue,
  Component,
  Prop,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import WebhooksMixin from "@/mixins/http/WebhooksMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import Preview from "@/components/Order/Preview.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import { Webhook } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const getTag = "get_webhooks";
const destroyTag = "destroy_webhooks";

@Component({
  components: {
    VPageContentSticky,
    DataTable,
    RoundThumb,
    Preview,
    PreviewModal
  }
})
export default class WarehouseItemList extends Mixins(WebhooksMixin) {
  @RegisterHttp(getTag) readonly getRequest!: RequestData;
  @RegisterHttp(destroyTag) readonly destroyRequest!: RequestData;
  @Ref() confirmModal: HTMLDivElement;

  items: Webhook[] = [];
  page = 1;

  columns = [
    { name: "name", label: "Nome" },
    { name: "topic", label: "Topic" },
    { name: "status", label: "Stato" },
    { name: "triggers", label: "Chiamate" }
  ];

  created() {
    this.subscribe();
  }

  statusClass(status: string) {
    switch (status) {
      case "active":
        return "bg-success";
      case "paused":
        return "bg-warning";
      case "disabled":
        return "bg-danger";
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/dispatchSetSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source !== sourceStore.currentSource) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  async loadItems() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id
      }
    };

    const [res, errors] = await easync(
      this.getWebhooks(data, getTag)
    );

    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...res);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
