



































































































import { Component, Prop, Vue, Mixins, Ref } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import PrintLinesMixin from "@/mixins/http/PrintLinesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { showToast } from "@/utils/Toast";
import PrintSideSelect from "@/components/PrintSide/Select.vue";
import { PrintLine } from "@/models";
import FileUploader from "@/components/FileUploader.vue";
import { PrintLinePayload } from "@/models/PrintLine";
import WarehouseItemSelect from "@/components/WarehouseItem/Select.vue";
import { WarehouseItem } from "../../models";
import ColorPicker from "@/components/VColorPicker.vue";

const updateTag = "update_print_template";
const createTag = "create_print_template";

@Component({
  components: {
    VModal,
    FileUploader,
    ColorPicker,
    PrintSideSelect,
    WarehouseItemSelect,
  },
})
export default class PrintLineModal extends Mixins(PrintLinesMixin) {
  item: PrintLinePayload = null;
  @Ref() readonly modal!: VModal;
  content = "";
  isNew = false;

  @RegisterHttp(createTag) createRequest?: RequestData;
  @RegisterHttp(updateTag) updateRequest?: RequestData;

  canceled!: any | null;

  get warehouseItem() {
    return this.item?.configuration?.warehouseItem;
  }
  get warehouseItemId() {
    return this.warehouseItem?.id;
  }

  get colors() {
    return this.item?.configuration?.warehouseItem?.colors || [];
  }

  get url() {
    return this.file?.url || this.item.url;
  }

  get printSideId() {
    // if(!this.item?.printSideId){
    //   return null;
    // }
    return this.item?.printSideId?.toString();
  }

  get warehouseItemFilter() {
    return { product: this.item.printableId };
  }

  onPrintSideSelected(id: string) {
    this.item.printSideId = id;
  }

  get printSideFilter() {
    return { product: this.item.printableId };
  }

  onWarehouseItemSelected(item: WarehouseItem) {
    this.item.configuration.warehouseItem = item;
  }

  get valid() {
    if (!this.item) {
      return false;
    }
    return (
      this.item.printableId &&
      this.item.configuration?.warehouseItem &&
      this.item.printableType
    );
  }

  title() {
    return `${this.isNew ? "Aggiungi" : "Modifica"} prodotto di base`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.$emit("confirmed", this.item);
      this.modal.hide();
    }
  }

  protected onHidden() {
    this.reset();
    if (this.canceled) {
      this.canceled();
    }
  }

  edit(item: PrintLine) {
    this.isNew = false;
    this.modal.show();
    this.item = PrintLinePayload.fromModel(item);
    this.item.printableType = "Product";
  }

  reset() {
    this.item = null;
  }

  get file() {
    return this.item.file;
  }

  set file(file: any){
    this.item.file = file;
  }

  create(productId: string) {
    this.isNew = true;
    this.modal.show();
    this.item = PrintLinePayload.empty();
    this.item.printableId = productId;
    this.item.printableType = "Product";
  }

  get loading() {
    return this.createRequest?.loading || this.updateRequest?.loading;
  }

  async save() {

     let formData = new FormData();

      formData.append('printSideId', this.printSideId);
       formData.append('quantity', this.item.quantity.toString());
       formData.append('position', this.item.position.toString());
       formData.append('printableType', this.item.printableType);
       formData.append('printableId', this.item.printableId);



    formData.append("configuration", JSON.stringify(this.item.configuration));
    if (this.item.file) {
      formData.append("file", this.item.file.file);
    }
    try {
      const resp = this.isNew
        ? await this.createPrintLine(formData)
        : await this.updatePrintLine(this.item.id.toString(),formData);
      const emitTag = this.isNew ? "created" : "updated";
      this.$emit(emitTag, resp);
      this.modal.hide();
      showToast("Salvataggio effettuato!");
    } catch (err) {
      showToast("Salvataggio fallito!", {
        type: "error",
      });
    }
  }
}
