























































import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import WarehouseItemSelect from "@/components/WarehouseItem/Select.vue";
import { Product, WoocommerceProduct } from "@/models";
import ProductWarehouseItemsMixin, {
  ProductWarehouseItemTags,
} from "@/mixins/http/ProductWarehouseItemsMixin";
import { ProductWarehouseItem } from "../../models";
import ConfirmModal from "@/components/ConfirmModal.vue";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import { showErrorToast } from "@/utils/Toast";
import { easync } from "@/utils/http";
import ProductWarehouseItemModal from "@/components/ProductWarehouseItem/Modal.vue";

@Component({
  components: {
    ConfirmModal,
    DataTable,
    WarehouseItemSelect,
    ProductWarehouseItemModal,
  },
})
export default class ProductWarehouseItemList extends Mixins(
  ProductWarehouseItemsMixin
) {
  @Prop({ required: true }) readonly item!: Product;

  @Ref() readonly productWarehouseItemModal: any;

  @Ref() confirmModal: HTMLDivElement;

  private productWarehouseItems: ProductWarehouseItem[] = [];

  mounted() {
    this.load();
  }

  get loading() {
    return false;
  }

  private columns = [
    {
      name: "name",
      label: "Prodotto di base",
    },
    {
      name: "quantity",
      label: "Pezzi da movimentare",
    },
    {
      name: "actions",
      label: "",
    },
  ];

  onAdd() {
    (this.productWarehouseItemModal as any).create(this.item.id);
  }

  onEdit(item: ProductWarehouseItem) {
    (this.productWarehouseItemModal as any).edit(item);
  }

  async onDelete(id: string) {
    const [data, errors] = await easync(
      this.destroyProductWarehouseItem(id)
    );
    if (errors) {
      showErrorToast("Si è verificato un errore");
      return;
    }

    this.load();
  }

  get payload() {
    return {
      filter: {
        product: this.item.id,
      },
    };
  }

  get tag() {
    return ProductWarehouseItemTags.Index;
  }

  async load() {
    const [data, errors] = await easync(
      this.getProductWarehouseItems(this.payload)
    );
    if (errors) {
      showErrorToast(
        "Si è verificato un errore nel caricamento degli prodotti di base"
      );
      return;
    } else {
      this.productWarehouseItems = data;
    }
  }

  async openConfirmModal(item: ProductWarehouseItem) {
    const message = "Sei sicuro di voler dissociare questo prodotto di base?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.onDelete(item.id);
    }
  }
}
