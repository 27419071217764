













import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/VSelect.vue";
import OrderStatusesMixin from "@/mixins/http/OrderStatusesMixin";
import { RequestData } from "@/store/types/RequestStore";
import { namespace } from "vuex-class";
import { Source } from "@/models";
import { OrderStatus } from "@/models";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";
import { RegisterHttp } from "@/utils/Decorators";

const tag = 'get_order_statuses_tag';

@Component({ components: { VSelect } })
export default class OrderStatusSelect extends Mixins(OrderStatusesMixin) {
  @Prop({required: false})
  readonly selected!: any | null;

  @Prop()
  queryKey?: string;

  @Prop()
  label?: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: false })
  preselect: boolean;

  @Prop({ default: false })
  actsAsFilter: boolean;

  @Prop()
  filter: any;

  options: VSelectOption[] = [];

  private value: any | null = this.selected || null;

  private items: any[] = [];

  @RegisterHttp(tag) readonly request!: RequestData;

  mounted() {
    this.loadItems();
  }

  @Watch('selected')
  onSelectedChanged(selected: string) {

    this.value = selected;
    this.onItemsReady();
  }

  get payload() {
    let data: any = {
      filter: {source: sourceStore.currentSource.id},
      page: {
        size: 100
      }
    };

    if (this.filter) {
      data.filter = this.filter;
    }

    return data;
  }

  get filterDefault() {
    return { id: "all", text: "All" };
  }

  get loading() {
    return this.request?.loading;
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);
     this.$emit("inputRaw", this.items.find(o => o.id === id));

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected)?.id;
    }
  }

  async loadItems() {
    if (this.actsAsFilter) {
      this.options.unshift(this.filterDefault);
    }

    const [data, errors] = await easync(this.getOrderStatuses(this.payload, tag));
    if (errors) {
      showErrorToast();
      return;
    }

    this.items = data;
    this.options = this.items.map((o: OrderStatus) => {
      return { id: o.id, text: `${o.name}` };
    });
    this.onItemsReady();
  }
}
