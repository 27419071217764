




































import { Component, Mixins } from "vue-property-decorator";
import OrdersMixin, { OrderTags } from "@/mixins/http/OrdersMixin";
import { httpStore, sourceStore } from "../../store/typed";
import { easync } from "@/utils/http";
import ClickOutside from "@/directives/ClickOutside";

@Component({directives: {ClickOutside}})
export default class Importer extends Mixins(OrdersMixin) {
  private externalId: string | null | number = null;
  private error: string | null = null;
  private openDropdown = false

  get source() {
    return sourceStore.currentSource;
  }

  hideDropdown() {
    this.openDropdown = false;
  }

  get payload() {
    return {
      sourceId: this.source?.id,
      externalId: this.externalId,
    };
  }

  get loading() {
    return httpStore.status[this.tag]?.loading;
  }

  get tag() {
    return OrderTags.Import + this.externalId;
  }

  reset() {
    this.externalId = null;
    this.error = null;
  }

  async startImport() {
    const [data, errors] = await easync(this.importOrder(this.payload));

    if (data) {
      this.reset();
      this.$router.push({ name: "orders_show", params: { id: data.id } });
    } else {
      this.error = `Non è stato possibile importare l'ordine ${this.externalId}. Potrebbe essere già presente nel tuo store`;
    }
  }
}
