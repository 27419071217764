
















































import {  Customer } from '@/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class CustomerSourceData extends Vue {
  @Prop() item: Customer;

  get sourceType() {
    if (!this.item) {
      return "";
    }
    if (this.item.constructor.name.toLowerCase().includes('woocommerce')) {
      return 'Woocommerce';
    }else {
      return "";
    }
  }
}
