





































































import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import WarehouseItemSelect from "@/components/WarehouseItem/Select.vue";
import { Product } from "@/models";
import PrintLinesMixin, {
  PrintLineTags
} from "@/mixins/http/PrintLinesMixin";
import { PrintLine } from "../../models";
import ConfirmModal from "@/components/ConfirmModal.vue";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import { showErrorToast } from "@/utils/Toast";
import { easync } from "@/utils/http";
import PrintLineModal from "@/components/PrintLine/Modal.vue";

@Component({
  components: {
    ConfirmModal,
    DataTable,
    WarehouseItemSelect,
    PrintLineModal,
  },
})
export default class PrintLinesData extends Mixins(
  PrintLinesMixin
) {
  @Prop({ required: true }) readonly item!: Product;

  @Ref() readonly printLineModal: any;

  @Ref() confirmModal: HTMLDivElement;

  private printLines: PrintLine[] = [];

  mounted() {
    this.load();
  }

  get loading() {
    return false;
  }

  warehouseItemName(value: PrintLine){
    return value?.configuration?.warehouseItem?.name;
  }

  warehouseItemColorStyle(value: PrintLine){
    return {background: value?.configuration?.color, width: "20px", height: "20px"};
  }


  private columns = [
    {
      name: "image",
      label: "Immagine",
    },
     {
      name: "quantity",
      label: "Quantità",
    },
    {
      name: "warehouseItem",
      label: "Stampato sul prodotto di base",
    },
    {
      name: "printSide",
      label: "Lato di stampa",
    },
    {
      name: "actions",
      label: "",
    },
  ];

  onAdd() {
    (this.printLineModal as any).create(this.item.id);
  }

  onEdit(item: PrintLine) {
    (this.printLineModal as any).edit(item);
  }

  async onDelete(id: string) {
    const [data, errors] = await easync(
      this.destroyPrintLine(id)
    );
    if (errors) {
      showErrorToast("Si è verificato un errore");
      return;
    }

    this.load();
  }

  get payload() {
    return {
      filter: {
        printableId: this.item.id,
        printableType: 'Product',
      },
    };
  }

  get tag() {
    return PrintLineTags.Index;
  }

  async load() {
    const [data, errors] = await easync(
      this.getPrintLines(this.payload)
    );
    if (errors) {
      showErrorToast(
        "Si è verificato un errore nel caricamento delle immagini di stampa"
      );
      return;
    } else {
      this.printLines = data;
    }
  }

  async openConfirmModal(item: PrintLine) {
    const message = "Sei sicuro di voler dissociare quest'immagine di stampa?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.onDelete(item.id);
    }
  }
}
