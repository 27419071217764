
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_WEBHOOKS_URL = BASE_API_URL+"webhooks"

@Component
export default class WebhooksMixin extends Vue {

  getWebhooks(data: any, tag: string) {
    return this.$httpGetQuery(BASE_WEBHOOKS_URL, tag,data);
  }

  destroyWebhook(id: string|number, tag: string) {
    return this.$httpDelete(BASE_WEBHOOKS_URL+"/"+id, tag);
  }

  createWebhook(data: any, tag: string) {
    return this.$httpPost(BASE_WEBHOOKS_URL, tag, data);
  }

  updateWebhook(data: any, tag: string) {
     return this.$httpPut(BASE_WEBHOOKS_URL+"/"+data.id, tag, data);
  }
}
