



























































import ClickOutside from "../directives/ClickOutside";

import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({ directives: { ClickOutside } })
export default class BaseMultiSelect extends Vue {
  @Prop({ default: (): any[] => [] }) value: any[] | null;
  @Prop({ default: "Seleziona un valore" }) placeholder: string;
  @Prop({ default: "name" }) label: string;
  @Prop({ default: "" }) classes: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) confirmSelection: boolean;
  @Prop({ default: false }) showDropdown: boolean;
  @Prop({ default: false }) searchEnabled: boolean;

  // private data:  {[id: number]: number} = {9: 9};

  private dropdownVisible = this.showDropdown;
  private selected = this.value;
  private search: string | null = null;

  @Watch("value")
  onValueChanged(value: any[]) {
    this.selected = value;
  }

  @Watch("showDropdown")
  onShowDropdownChanged(value: boolean) {
    if (!value) {
      this.hideDropdown();
    }
  }

  @Watch("search")
  onSearchChanged(value: string) {
    this.$emit("search", value);
  }

  get containerCssClass() {
    return {
      "multiselect-open": this.dropdownVisible,
      "multiselect-disabled": this.disabled
    };
  }

  get selectionCssClass() {
    return {
      "multiselect-empty": this.selected?.length === 0,
      "multiselect-selected": this.selected?.length > 0,
      "multiselect-open": this.dropdownVisible
    };
  }

  get result() {
    if (this.selected?.length === 0) {
      return this.placeholder;
    } else {
      return this.selected?.map(e => e.name).join(", ");
    }
  }

  toggleDropdown() {
    if (this.disabled) {
      return;
    }

    this.dropdownVisible = !this.dropdownVisible;
    this.$emit("toggle-visible", this.dropdownVisible);

    if (this.searchEnabled && this.dropdownVisible) {
      this.$nextTick(() => {
        (this.$refs.searchInput as HTMLInputElement).focus();
      });
    }
  }

  hideDropdown() {
    this.dropdownVisible = false;
  }

  toggleDropdownOrClearResult() {
    if (this.disabled) {
      return;
    }

    if (this.selected.length === 0) {
      this.toggleDropdown();
      return;
    }

    this.selected = [];
    this.$emit("input", this.selected);
    this.$emit("cleared");
  }

  selectAll() {
    this.$emit("select-all");
  }

  reset() {
    this.selected = [];
    this.$emit("input", this.selected);
    this.$emit("reset");
  }

  apply() {
    this.$emit("apply");
  }

  onScroll(e: any) {
    this.$emit("scroll", e, this.$refs.scroll);
  }

  clearSearch() {
    this.search = null;
  }
}
