














































































































































import {
  Vue,
  Component,
  Prop,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import ProductLabelsMixin from "@/mixins/http/ProductLabelsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import FileUploader from "@/components/FileUploader.vue";
import { showErrorToast, showToast } from "@/utils/Toast";
import FieldForm from "@/components/ProductLabel/FieldForm.vue";
import { ProductLabelMetadata } from "@/models/ProductLabel";
import { ProductLabelField } from "@/models/ProductLabel";
import { Product } from "@/models";
import { ProductLabel } from "@/models";
import { easync } from "@/utils/http";

const tag = "create_product_label";

@Component({
  components: {
    FileUploader,
    FieldForm
  }
})
export default class ProductLabelCreate extends Mixins(ProductLabelsMixin) {
  @RegisterHttp(tag) createRequest: RequestData;

  @Prop() readonly label!: {
    id: string;
    metadata: ProductLabelMetadata;
    productId: string;
    quantity: number;
  };

  private item = this.label;

  @Prop({ default: "md" }) size: string;

  @Ref() readonly fieldForm!: FieldForm;

  showForm = false;

  file: any = null;
  cutFile: any = null;

  private dynamic = false;
  private selectedIndex: number | null = null;

  showDynamicForm() {
    this.dynamic = true;
    this.showForm = true;
  }

  get fields() {
    return this.item.metadata.fields;
  }

  createField() {
    this.fieldForm.create();
  }

  typeIcon(type: "text" | "image") {
    return type === "image" ? "image" : "heading";
  }

  columnLabel(column: string) {
    return Product.selectableColumns.find(o => column === o.id)?.label;
  }

  editField(field: ProductLabelField, index: number) {
    this.fieldForm.edit(field);
    this.selectedIndex = index;
  }

  get imageClass() {
    return [`product-label-image-${this.size} product-label-image`];
  }

  get formClass() {
    return [`product-label-form-${this.size}`];
  }

  onDestroy(index: number) {
    this.item.metadata.fields.splice(index, 1);
  }

  onUpdated(item: ProductLabelField) {
    this.$set(this.item.metadata.fields, this.selectedIndex, item);
    this.selectedIndex = null;
  }
  onCreated(field: ProductLabelField) {
    this.item.metadata.fields.push(field);
  }

  @Watch("showForm")
  onShowFormChanged(value: boolean) {
    if (value) {
      this.item.metadata = new ProductLabelMetadata();
      this.item.productId = this.label.productId;
      this.item.quantity = this.label.quantity;
    } else {
      this.item = this.label;
    }
  }

  reset() {
    this.item = this.label;
    this.file = null;
    this.dynamic = false;
    this.cutFile = null;
  }

  cancel() {
    // this.reset();
    this.file = null;
    this.cutFile = null;
    this.dynamic = false;
    this.showForm = false;
  }

  async create() {
    let formData = new FormData();
    const payload = { ...this.item };
    formData.append("metadata", JSON.stringify(payload.metadata));
    formData.append("kind", this.dynamic ? "dynamic" : "static");
    delete payload.metadata;
    for (const key in payload) {
      formData.append(key, (payload as any)[key]);
    }

    if (this.file) {
      formData.append("image", this.file.file);
    }
    if (this.cutFile) {
      formData.append("cut", this.cutFile.file);
    }

    const [data, errors] = await easync(this.createProductLabel(formData, tag));
    if (errors) {
      showErrorToast();
      return;
    }

    this.$emit("created", data);
    this.showForm = false;
    showToast("Etichetta creata!");
    this.reset();
  }

  get loading() {
    return this.createRequest?.loading;
  }
}
