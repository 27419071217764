






















import { Vue, Component, Prop } from "vue-property-decorator";

export interface StepItem {
  status: "complete" | "active" | "disabled";
  lowerLabel?: string;
  upperLabel?: string;
  icon?: string;
}

@Component({})
export default class StepBar extends Vue {
  @Prop() steps!: StepItem[];

  styleIcon(step: StepItem) {
    switch(step.status) {

      case 'complete':
        return 'text-primary';
      case 'disabled':
        return 'text-muted';
      case 'active':
        return 'text-primary-pale';
    }
  }
}
