var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"customer-orders w-100"},[_c('p',{staticClass:"page-subtitle"},[_vm._v("Ordini")]),_c('div',{staticClass:"d-flex mt-2"},[_c('b',[_vm._v("Totale")]),_c('p',{staticClass:"ml-2 my-auto text-success"},[_vm._v(_vm._s(_vm._f("currency")(_vm.customer.ordersTotal)))])]),_c('data-table',{attrs:{"columns":_vm.columns,"values":_vm.orders,"loading":_vm.loading},on:{"selected":_vm.onSelected},scopedSlots:_vm._u([{key:"column_external_id",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 font-weight-semibold"},[_vm._v(_vm._s(value.externalId))])]}},{key:"column_total",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 text-success"},[_vm._v(_vm._s(_vm._f("currency")(value.total)))])]}},{key:"column_status",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"badge badge-primary my-auto ml-2",style:(_vm.statusStyle(value))},[_vm._v(_vm._s(value.orderStatus.name))])]}},{key:"column_order_lines",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(value.orderLines.length))])]}},{key:"column_created_at",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 text-muted"},[_c('small',[_vm._v(" "+_vm._s(_vm._f("moment")(value.createdAt,"DD/MM/YYYY"))+" alle "+_vm._s(_vm._f("moment")(value.createdAt,"HH:mm"))+" ")])])]}},{key:"column_approved",fn:function(ref){
var value = ref.value;
return [(value.approved)?_c('unicon',{staticClass:"text-success",attrs:{"name":"check-circle"}}):_c('unicon',{staticClass:"text-danger",attrs:{"name":"exclamation-circle"}})]}},{key:"loading",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"page-content p-5 d-flex w-100 my-5"},[_c('fa-icon',{staticClass:"text-primary m-auto",attrs:{"icon":"circle-notch","size":"3x","spin":""}})],1):_vm._e()]},proxy:true},{key:"column_name",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 clickable text-primary"},[_vm._v(_vm._s(value.name))])]}},{key:"no-results-label",fn:function(){return [(_vm.orders && _vm.orders.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non c'è ancora nessun ordine qui","secondaryText":"Questo cliente non ha ancora effettuato ordini"}}):_vm._e()]},proxy:true}])}),(_vm.meta)?_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('v-paginator',{staticClass:"mt-3",attrs:{"meta":_vm.meta,"loading":_vm.loading,"directionalArrows":false},on:{"change":function($event){return _vm.loadOrders($event)}}}),_c('div',{staticClass:"ml-3"},[(_vm.loading)?_c('fa-icon',{staticClass:"m-auto text-primary",attrs:{"icon":"spinner","size":"2x","spin":""}}):_vm._e()],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }