
import { OrderStatus } from '@/models';
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_ORDER_STATUS_URL = BASE_API_URL+"order_statuses"

@Component
export default class OrderStatusesMixin extends Vue {


  getOrderStatuses(data: any, tag: string) {
    return this.$httpGetQuery( BASE_ORDER_STATUS_URL, tag, data);
  }

  updateOrderStatus(data: any, tag: string) {
    return this.$httpPut( BASE_ORDER_STATUS_URL+"/"+data.id,tag, data);
  }
}
