


















































import { Component, Prop, Vue, Mixins, Ref } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import ProductWarehouseItemsMixin from "@/mixins/http/ProductWarehouseItemsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { showToast } from "@/utils/Toast";
import { ProductWarehouseItem } from "@/models";
import { ProductWarehouseItemPayload } from "@/models/ProductWarehouseItem";
import WarehouseItemSelect from "@/components/WarehouseItem/Select.vue";

const updateTag = "update_print_template";
const createTag = "create_print_template";

@Component({
  components: {
    VModal,
    WarehouseItemSelect,
  },
})
export default class ProductWarehouseItemModal extends Mixins(
  ProductWarehouseItemsMixin
) {
  item: ProductWarehouseItemPayload = null;
  @Ref() readonly modal!: VModal;
  content = "";
  isNew = false;

  @RegisterHttp(createTag) createRequest?: RequestData;
  @RegisterHttp(updateTag) updateRequest?: RequestData;

  canceled!: any | null;

  get warehouseItemId() {
    return this.item?.warehouseItemId;
  }

  onWarehouseItemSelected(id: string) {
    this.item.warehouseItemId = id;
  }

  get valid() {
    if (!this.item) {
      return false;
    }
    return (
      this.item.productId &&
      this.item.warehouseItemId &&
      this.item.quantity
    );
  }

  title() {
    return `${this.isNew ? "Aggiungi" : "Modifica"} prodotto di base`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.$emit("confirmed", this.item);
      this.modal.hide();
    }
  }

  protected onHidden() {
    this.reset();
    if (this.canceled) {
      this.canceled();
    }
  }

  edit(item: ProductWarehouseItem) {
    this.isNew = false;
    this.modal.show();
    this.item = ProductWarehouseItemPayload.fromModel(item);
  }

  reset() {
    this.item = null;
  }

  create(productId: string) {
    this.isNew = true;
    this.modal.show();
    this.item = ProductWarehouseItemPayload.empty();
    this.item.productId = productId;
  }

  get loading() {
    return this.createRequest?.loading || this.updateRequest?.loading;
  }

  async save() {
    try {
      const resp = this.isNew
        ? await this.createProductWarehouseItem(this.item)
        : await this.updateProductWarehouseItem(this.item);
      const emitTag = this.isNew ? "created" : "updated";
      this.$emit(emitTag, resp);
      this.modal.hide();
      showToast("Salvataggio effettuato!");
    } catch (err) {
      showToast("Salvataggio fallito!", {
        type: "error",
      });
    }
  }
}
