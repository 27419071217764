




















import { Order, Source } from "@/models";
import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import SourcesMixin from "@/mixins/http/SourcesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import { Product } from "@/models";
import { showErrorToast, showToast } from "@/utils/Toast";
import SourceData from "@/components/Source/SourceData.vue";
import { WoocommerceSource } from "@/models";
import { WoocommerceConfiguration } from "@/models/woocommerce/Source";
import { easync } from "@/utils/http";

const createItemTag = "create_source";

@Component({
  components: { SourceData }
})
export default class SourceCreate extends Mixins(SourcesMixin) {
  @RegisterHttp(createItemTag) createItemRequest: RequestData;

  item: WoocommerceSource = new WoocommerceSource({});

  created() {
    this.item.configuration = new WoocommerceConfiguration();
  }

  get id() {
    return parseInt(this.$route.params.id);
  }

  async create(data: any = null) {
    const payload = { id: this.item.id, ...data };

    const [res, errors] = await easync(
      this.createSource(payload, createItemTag)
    );

    if (errors) {
      showErrorToast("Errore nella creazione del negozio");
      return;
    }

    this.item = res;
    this.$router.push({
      name: "source_detail",
      params: { id: this.item.id }
    });
    showToast("Negozio creato!");
  }

  get loading() {
    return this.createItemRequest?.loading;
  }

  get meta() {
    return this.createItemRequest?.meta;
  }
}
