
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';
const BASE_PRODUCT_URL = BASE_API_URL+"/product_warehouse_items"

export enum ProductWarehouseItemTags {
   Update = "ProductWarehouseItemUpdate",
   Create = "ProductWarehouseItemCreate",
   Index = "ProductWarehouseItemIndex",
   Destroy = "ProductWarehouseItemDestroy"
}
@Component
export default class ProductWarehouseItemsMixin extends Vue {

  getProductWarehouseItems(data: any) {
    return this.$httpGetQuery(BASE_PRODUCT_URL, ProductWarehouseItemTags.Index,data);
  }

  destroyProductWarehouseItem(id: string|number) {
    return this.$httpDelete(BASE_PRODUCT_URL+"/"+id, ProductWarehouseItemTags.Destroy+id);
  }

  createProductWarehouseItem(data: any) {
    return this.$httpPost(BASE_PRODUCT_URL, ProductWarehouseItemTags.Create, data);
  }

  updateProductWarehouseItem(data: any) {
    return this.$httpPut(BASE_PRODUCT_URL+"/"+data.id, ProductWarehouseItemTags.Update, data);
  }

}
