

























import { Prop, Component, Vue } from "vue-property-decorator";

@Component({})
export default class VAccordion extends Vue {
  @Prop({ default: false, required: true }) opened!: boolean;
  closed = true;

  async mounted() {
    await this.$nextTick();
    this.closed = !this.opened;
  }

  get isClosed() {
    return this.closed;
  }

  toggleOpenClose() {
    this.closed = !this.closed;
  }
}
