

































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { PrintLine, Product, WarehouseItem } from "@/models";
import ProductLabels from "@/components/ProductLabel/Grid.vue";
import PrintCategorySelect from "@/components/PrintCategory/Select.vue";
import PrintTechnologySelect from "@/components/PrintTechnology/Select.vue";
import PrintTemplateSelect from "@/components/PrintTemplate/Select.vue";
import Throttler from "@/utils/Throttler";
import CutFileSelect from "@/components/CutFile/Select.vue";
import CutFileUpload from "@/components/Product/CutFileUpload.vue";
import { ProductLabel } from "@/models";
import PrintLinesList from "@/components/PrintLine/List.vue";
@Component({
  components: {
    PrintLinesList,
    CutFileSelect,
    ProductLabels,
    PrintCategorySelect,
    CutFileUpload,
    PrintTechnologySelect,
    PrintTemplateSelect
  }
})
export default class PrintData extends Vue {
  @Prop() item!: Product;
  private throttler: Throttler = new Throttler();

  emitUpdate(data: any) {
    this.throttler.run(() => this.$emit("updated", data));
  }

  get printCategoryId() {
    return (this.item as any)?.printCategoryId?.toString();
  }

  get printTechnologyId() {
    return (this.item as any)?.printTechnologyId?.toString();
  }

  get cutFileId() {
    return (this.item as any)?.cutFileId?.toString();
  }

  get printTemplateId() {
    return (this.item as any)?.printTemplateId?.toString();
  }

  onPrintLineCreated(printLine: PrintLine) {
    this.item.printLines.unshift(printLine);
  }

  onPrintLineDestroyed(printLine: PrintLine) {
    this.item.printLines = this.item.printLines.filter(
      o => o.id !== printLine.id
    );
  }

  onPrintLineUpdated(printLine: PrintLine) {
    const index = this.item.printLines.findIndex(o => o.id === printLine.id);
    this.$set(this.item.printLines, index, printLine);
  }

  onProductLabelCreated(label: ProductLabel) {
    this.item.labels.unshift(label);
  }

  onProductLabelDestroyed(label: ProductLabel) {
    this.item.labels = this.item.labels.filter(o => o.id !== label.id);
  }

  onProductLabelUpdated(label: ProductLabel) {
    const index = this.item.labels.findIndex(o => o.id === label.id);
    this.$set(this.item.labels, index, label);
  }

  get printables() {
    if (!this.item) {
      return [];
    }

    return this.item.warehouseItems.map((el: WarehouseItem) =>{
      return {
        type: "Product",
        id: this.item.id,
        colors: el.colors
      }
    });
  }

  onCutFileChanged(item: Product) {
    this.item.cut = item.cut;
  }
}
