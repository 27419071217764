



















import { Order, Source } from "@/models";
import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SourcesMixin from "@/mixins/http/SourcesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import { showErrorToast, showToast } from "@/utils/Toast";
import SourceData from "@/components/Source/SourceData.vue";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const getItemTag = "get_source";
const updateItemTag = "update_source";

@Component({
  components: { SourceData }
})
export default class SourceShow extends Mixins(SourcesMixin) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(updateItemTag) updateItemRequest: RequestData;

  item: Source = null;

  created() {
    this.subscribe();
  }

  mounted() {
    this.load();
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.load();
    }
  }

  get id() {
    return parseInt(sourceStore.currentSource.id);
  }

  async update(data: any = null) {
    const payload = { id: this.item.id, ...data };
    const [res, errors] = await easync(
      this.updateSource(payload, updateItemTag)
    );

    if (errors) {
      showErrorToast("Errore nell'aggiornamento del negozio");
      return;
    }

    this.item = res;
    sourceStore.dispatchSetCurrentSource(res);
    showToast("Negozio aggiornato!");
  }

  async load() {
    const [res, errors] = await easync(
      this.getSource(this.id, getItemTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.item = res;
  }

  get loading() {
    return this.getItemRequest?.loading;
  }

  get meta() {
    return this.getItemRequest?.meta;
  }
}
