
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_PRINT_LINE_URL = BASE_API_URL+"print_lines"

export enum PrintLineTags {

  Index="PrintLineIndex",
  Update="PrintLineUpdate",
  Create="PrintLineCreate",
  Destroy="PrintLineDestroy",
}

@Component
export default class PrintLinesMixin extends Vue {

  getPrintLines(data: any) {
    return this.$httpGetQuery(BASE_PRINT_LINE_URL, PrintLineTags.Index,data);
  }

  destroyPrintLine(id: string|number) {
    return this.$httpDelete(BASE_PRINT_LINE_URL+"/"+id, PrintLineTags.Destroy+id);
  }

  createPrintLine(data: any) {
    return this.$httpPost(BASE_PRINT_LINE_URL, PrintLineTags.Create, data);
  }

  updatePrintLine(id: string, data: any) {
     return this.$httpPut(BASE_PRINT_LINE_URL+"/"+id, PrintLineTags.Update+id, data);
  }
}
