

























import { Vue, Component, Prop } from 'vue-property-decorator';
import { chunkArrayInGroups } from '@/utils/Chunker';
import ClickOutside from '@/directives/ClickOutside';

@Component({directives: {
  ClickOutside
}})
export default class ColorPicker extends Vue {
  @Prop({
    default: (): any[] => {
      return [];
    }
  })
  colors!: string[];

  @Prop({default: 4})
  columns!: number;

  closeDropdown() {
    this.open = false;
  }

  onColorClick(color: string) {
    this.$emit('input', color);
    this.closeDropdown();
  }

  @Prop()
  value: string;

  get colorChunks() {
    return chunkArrayInGroups(this.colors, this.columns);
  }

  get col() {
    return Math.round(12/this.columns);
  }

  get colorStyle() {
    const data: any = {background: this.value};

      (data as any)['border'] = "1px black solid";

    return data;
  }

  open = false;
}
