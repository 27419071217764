


























































































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import SourcesMixin, { LIST_TAG } from "@/mixins/http/SourcesMixin";

import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { Source, User } from "@/models";
import { Getter, namespace } from "vuex-class";
import { currentUserStore, sourceStore } from "@/store/typed";

@Component({
  components: {
    VPageContentSticky
  }
})
export default class SourceList extends Mixins(SourcesMixin) {
  private sources: Source[] = [];
  
  mounted() {
    this.load();
  }

  select(source: Source) {
    sourceStore.dispatchSetCurrentSource(source);
  }

  async load() {
    try {
      this.sources = await this.getSources(this.payload, LIST_TAG);
    } catch (e) {
      console.log(e)
    }
  }

  onEdit(id: string) {
    this.$router.push({ name: "source_detail", params: { id: id } });
  }

  onAdd(id: string) {
    this.$router.push({ name: "source_create", params: { id: id } });
  }

  current(id: string) {
    return id === sourceStore.currentSource?.id;
  }

  get payload() {
    const data: any = { filter: {} };
    if (currentUserStore.currentUser.isCompanyRole) {
      data.filter.company = currentUserStore.currentUser.company.id;
    }
    return data;
  }
}
