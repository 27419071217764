




















import { Source } from "@/models";
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SourceData from "@/components/Customer/SourceData.vue";
import CustomersMixin from "@/mixins/http/CustomersMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import RoundThumb from "@/components/RoundThumb.vue";
import Preview from "@/components/Order/Preview.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import { Customer } from "@/models";
import { showToast } from "@/utils/Toast";
import CustomerOrders from "@/components/Customer/Orders.vue";
import { easync } from "@/utils/http";
import CustomerIntegrations from "@/components/Customer/Integrations.vue";

const getItemTag = "get_customer";
const updateItemTag = "update_customer";

@Component({
  components: { RoundThumb,CustomerIntegrations, Preview, PreviewModal, SourceData, CustomerOrders },
})
export default class CustomerShow extends Mixins(CustomersMixin) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(updateItemTag) updateItemRequest: RequestData;

  item: Customer = null;

  mounted() {
    this.load();
  }

  get id() {
    return parseInt(this.$route.params.id);
  }

  async update(data: any = null) {
    const payload = { id: this.item.id, ...data };

    const [res, errors] = await easync(
      this.updateCustomer(payload, updateItemTag)
    );
    if (errors) {
      showToast("Errore nell'aggiornamento del cliente", { type: "error" });
      return;
    }

    this.item = res;
    showToast("Cliente aggiornato!");
  }

  async load() {
    const [data, errors] = await easync(
      this.getCustomer(this.id, getItemTag)
    );
    if (errors) {
      showToast("Si è verificato un errore", { type: "error" });
      return;
    }

    this.item = data;
  }

  get loading() {
    return this.getItemRequest?.loading;
  }

  get meta() {
    return this.getItemRequest?.meta;
  }
}
