var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-modal',{ref:"confirmModal"}),_c('print-line-modal',{ref:"printLineModal",on:{"created":_vm.load,"updated":_vm.load}}),(_vm.item)?_c('div',{staticClass:"d-flex w-100"},[_vm._m(0),_c('button',{staticClass:"btn btn-primary ml-auto my-auto",on:{"click":_vm.onAdd}},[_vm._v(" Crea ")])]):_vm._e(),_c('data-table',{staticClass:"table mt-2",attrs:{"columns":_vm.columns,"values":_vm.printLines,"loading":false},scopedSlots:_vm._u([{key:"column_image",fn:function(ref){
var value = ref.value;
return [_c('img',{staticClass:"thumb squared thumb-md\n        rounded",attrs:{"src":value.url}})]}},{key:"column_name",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 text-bold"},[_vm._v(_vm._s(value))])]}},{key:"column_printSide",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 text-bold"},[_vm._v(_vm._s(value.printSide.name))])]}},{key:"column_warehouseItem",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mb-0 text-bold my-auto"},[_vm._v(_vm._s(_vm.warehouseItemName(value)))]),_c('small',{staticClass:"my-auto ml-2 text-muted"},[_vm._v("stampato su colore")]),_c('div',{staticClass:"thumb my-auto ml-2",style:(_vm.warehouseItemColorStyle(value))})])]}},{key:"no-results-label",fn:function(){return [(_vm.printLines && _vm.printLines.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non hai ancora associato immagini di stampa","action":"Crea nuovo"},on:{"action":_vm.onAdd}}):_vm._e()]},proxy:true},{key:"column_actions",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('fa-icon',{staticClass:"clickable text-primary ml-auto",attrs:{"icon":"edit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onEdit(value)}}}),_c('fa-icon',{staticClass:"clickable ml-3 text-primary",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openConfirmModal(value)}}})],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"my-auto"},[_c('small',[_vm._v("Crea nuove immagini di stampa per configurare i media che andranno in stampa ogni volta che un'unità di questo prodotto sarà presente in un ordine")])])}]

export { render, staticRenderFns }