

export function camelToUnderscore(key: string) {

	if (!key) {
		return '';
	}
	const result = key.replace(/([A-Z])/g, " $1");
	return result.split(' ').join('_').toLowerCase();
}

export function queryParams(params: any) {
	return Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')
}

export function capitalize(s: string) {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
  }

  export function firstLowerCase(s: string) {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toLowerCase() + s.slice(1)
  }

