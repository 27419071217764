




































import { Customer } from "@/models";
import CustomersMixin, { CustomerTags } from "@/mixins/http/CustomersMixin";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { namespace } from "vuex-class";
const http = namespace("http");

@Component({})
export default class CustomerIntegrations extends Mixins(CustomersMixin) {
  @Prop({ required: true }) readonly value!: Customer;
  @http.Getter status: (tag: string) => any;

  private item: Customer | null = this.value || null;

  get synced() {
    return this.item?.contactsResourceName;
  }

  async addToContacts() {
    const id = this.item.id;
    const [data, errors] = await easync(this.addCustomerToContacts(id));

    if (data && data.id) {
      this.item = data;
    } else {
      showErrorToast("Non è stato possibile aggiungere il contatto");
    }
  }

  get loading() {
    return (
      (this.status as any)[CustomerTags.AddToContacts]?.loading ||
      (this.status as any)[CustomerTags.RemoveFromContacts]?.loading
    );
  }

  async removeFromContacts() {
    const id = this.item.id;

    const [data, errors] = await easync(this.removeCustomerFromContacts(id));

    if (data && data.id) {
      this.item = data;
    } else {
      showErrorToast("Non è stato possibile rimuovere il contatto");
    }
  }
}
