






















































import { ProductLabelField, ProductLabelMetadata } from "@/models/ProductLabel";
import { Vue, Component, Prop } from "vue-property-decorator";
import ColumnSelect from "@/components/Product/ColumnSelect.vue";

@Component({
  components: {
    ColumnSelect
  }
})
export default class FieldForm extends Vue {
  private show = false;
  private isNew = false;
  private field: ProductLabelField = null;

  public create() {
    this.isNew = true;
    this.show = true;
    this.field = new ProductLabelField();
  }

  public edit(field: ProductLabelField) {
    this.isNew = false;
    this.show = true;
    this.field = field;
  }

  onColumnSelected(column: {
    id: string;
    label: string;
    type: "image" | "text";
  }) {
    this.field.type = column.type;
    this.field.column = column.id;
  }

  get isText() {
    return this.field.type == "text";
  }

  get isImage() {
    return this.field.type == "image";
  }

  get valid() {
    const general =
      this.field.type &&
      this.field.column &&
      this.field.position.x &&
      this.field.position.y;
    if (this.isText) {
      return general && this.field.fontSize;
    }
    if (this.isImage) {
      return general && this.field.size.width && this.field.size.height;
    }
    return general;
  }

  emitSaved() {
    this.$emit(this.isNew ? "created" : "updatd", this.field);
    this.hide();
  }

  reset() {
    this.hide();
    this.isNew = false;
    this.field = null;
  }

  hide() {
    this.show = false;
  }
}
