var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-page-content-sticky',{attrs:{"top":"0px","threshold":"88","root":".root-element"}},[_c('div',{staticClass:"page-header bg-white"},[_c('div',[_c('h3',{staticClass:"page-title my-auto"},[_vm._v("Prodotti")]),_c('incomplete-products')],1),_c('button',{staticClass:"ml-auto my-auto btn btn-outline-primary",on:{"click":_vm.openConfigurationDashboard}},[_vm._v(" Configurazione ")]),_c('button',{staticClass:"ml-3 my-auto btn btn-primary",on:{"click":_vm.dumpProducts}},[_vm._v(" Esporta "),(_vm.exporting)?_c('fa-icon',{staticClass:"text-white",attrs:{"spin":"","icon":"circle-notch"}}):_vm._e()],1)]),_c('div',{staticClass:"page-header pt-0 bg-white"},[_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col-4"},[_c('search',{attrs:{"load":_vm.searchItems,"placeholder":"Cerca.."},on:{"searched":_vm.onSearched,"cancel":_vm.onSearchCanceled}})],1)])])]),_c('div',{staticClass:"page-content"},[_c('data-table',{attrs:{"columns":_vm.columns,"values":_vm.items,"loading":_vm.loading},on:{"selected":_vm.onSelected},scopedSlots:_vm._u([{key:"column_price",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 text-success"},[_vm._v(_vm._s(_vm._f("currency")(value.price)))])]}},{key:"column_createdAt",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" "+_vm._s(_vm._f("moment")(value.createdAt,"DD/MM/YYYY"))+" alle "+_vm._s(_vm._f("moment")(value.createdAt,"HH:mm"))+" ")])]}},{key:"column_approved",fn:function(ref){
var value = ref.value;
return [(value.approved)?_c('unicon',{staticClass:"text-success",attrs:{"name":"check-circle"}}):_c('unicon',{staticClass:"text-danger",attrs:{"name":"exclamation-circle"}})]}},{key:"loading",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"page-content p-5 d-flex w-100 my-5"},[_c('fa-icon',{staticClass:"text-primary m-auto",attrs:{"icon":"circle-notch","size":"3x","spin":""}})],1):_vm._e()]},proxy:true},{key:"column_name",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mb-0 clickable text-primary"},[_vm._v(_vm._s(value.name))]),(value.b2b)?_c('span',{staticClass:"badge badge-secondary ml-3"},[_vm._v("B2B")]):_vm._e()])]}},{key:"no-results-label",fn:function(){return [(_vm.items && _vm.items.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non c'è ancora nessun prodotto qui","secondaryText":"Sincronizza il tuo negozio per vedere i tuoi prodotti"}}):_vm._e()]},proxy:true}])}),(_vm.meta)?_c('div',{staticClass:"d-flex"},[(_vm.page < _vm.meta.totalPages + 1)?_c('button',{staticClass:"btn btn-outline-primary mx-auto",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" Carica altri ")]):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }