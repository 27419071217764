









import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import VSelect, { VSelectOption } from '@/components/VSelect.vue';
import Product from '../../models/Product';
import { capitalize } from '@/utils/Typography';

@Component({ components: { VSelect } })
export default class ProductColumnSelect extends Vue {
  @Prop()
  selected!: string | null;

  @Prop()
  queryKey?: string;

  @Prop({ default: false })
  preselect: boolean;

  @Prop({ default: false })
  actsAsFilter: boolean;

  options: VSelectOption[] = [];

  private value: string | null = this.selected || null;

  mounted() {
    this.loadItems();
  }

  @Watch('selected')
  onSelectedChanged(selected: string) {
    this.value = selected;
    this.updateValue();
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit(
      'input',
      Product.selectableColumns.find(o => o.id === id)
    );

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  updateValue() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected)?.id;
    }
  }

  loadItems() {
    this.options = [
      ...Product.selectableColumns.map(o => {
        return { id: o.id, text: `${o.label} (${o.type})` };
      })
    ];

    if (this.actsAsFilter) {
      this.options.unshift({ id: 'all', text: 'Tutti' });
    }

    this.updateValue();
  }
}
