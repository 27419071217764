








































import { Order } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Coupon } from "../../models";

@Component({ components: {} })
export default class Coupons extends Vue {
  @Prop({ required: true }) readonly value!: Order;

  get coupons(): Array<Coupon> {
    return this.value.coupons;
  }

  openDetail(id: string){
    this.$router.push({name: 'coupons_show', params: {id: id}})
  }

  private icons = {
    percent: "percentage",
    fixed_cart: "shopping-cart-alt",
    fixed_product: "gift",

  };
}
