

























































import { Source } from "@/models";
import {
  Component,
  Mixins,
  Watch,

} from "vue-property-decorator";
import {   Getter, namespace } from "vuex-class";
import ShippingMethodsMixin from "@/mixins/http/ShippingMethodsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import Preview from "@/components/Order/Preview.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import SourcesMixin from "@/mixins/http/SourcesMixin";
import { showErrorToast, showToast } from "@/utils/Toast";
import { User } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const getTag = "get_shipping_methods";
const destroyTag = "destroy_shipping_methods";
const syncTag = "sync_shipping_methods";
@Component({
  components: {
    VPageContentSticky,
    DataTable,
    RoundThumb,
    Preview,
    PreviewModal,
  },
})
export default class ShippingMethod extends Mixins(
  ShippingMethodsMixin,
  SourcesMixin
) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(syncTag) syncRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;

  @Getter currentUser!: User;

  items: ShippingMethod[] = [];
  page = 1;

  columns = [
    { name: "name", label: "Nome" },
    { name: "code", label: "Codice" },
  ];

  created() {
    this.subscribe();
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source !== sourceStore.currentSource) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  async syncShippingMethods() {
    const [res, errors] = await easync(
      this.syncSourceShippingMethods(sourceStore.currentSource.id, syncTag)
    );

    if (errors) {
      showErrorToast("Si è verificato un errore durante la sincronizzazione: " + errors);
      return;
    }

    this.items = res;
    showToast("Metodi di spedizione sincronizzati!")
  }

  async loadItems() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id,
      },
    };

    const [res, errors] = await easync(
      this.getShippingMethods(data, getTag)
    );

    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...res);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get syncLoading() {
    return this.syncRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
