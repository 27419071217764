







































































import { Component, Mixins } from "vue-property-decorator";
import Accordion from "@/components/VAccordion.vue";
import FileUploader from "@/components/FileUploader.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import ProductsMixin from "@/mixins/http/ProductsMixin";
const FULL_IMPORT_TAG = "FULL_IMPORT_TAG";
import { namespace } from "vuex-class";
import { Source } from "@/models";
import { showToast } from "@/utils/Toast";

@Component({
  components: {
    Accordion,
    FileUploader,
  },
})
export default class FullImport extends Mixins(ProductsMixin) {
  @RegisterHttp(FULL_IMPORT_TAG) uploadRequest: RequestData;

  private quantity = 1;
  private overwrite = false;
  private file: any = null;
  private result: {errors: any[]; imported: {sheet: string, imported: number}[]} = null

  reset() {
    this.quantity = 1;
    this.overwrite = false;
    this.file= null;
    this.result = null;
  }

  get loading() {
    return this.uploadRequest?.loading;
  }

  get filename() {
    return this.file?.name
  }

  async upload() {
    let formdata = new FormData();
    formdata.append("file", this.file.file);
    this.result = await this.fullImport(
      formdata,
      FULL_IMPORT_TAG
    );
    showToast("Import completato");
  }
}
