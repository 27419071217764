



































































































































































































































































import { Source } from "@/models";
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import FileUploader from "@/components/FileUploader.vue";
import SourcesMixin from "@/mixins/http/SourcesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { showErrorToast, showToast } from "@/utils/Toast";
import ColorInput from "@/components/ColorInput.vue";
import OrderStatusSelect from "@/components/OrderStatus/Select.vue";
import { User } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const updateItemTag = "update_source";

@Component({
  components: { FileUploader, ColorInput, OrderStatusSelect },
})
export default class SourceData extends Mixins(SourcesMixin) {
  @Prop() item: Source;

  @RegisterHttp(updateItemTag) updateItemRequest: RequestData;

  @Getter currentUser: User;

  file: any = null;

  @Watch("file")
  onFileChanged(value: any, oldValue: any) {
    if (value) {
      this.updateLogo();
    }
  }

  get selectedPrintOrderStatusId() {
    return this.item.printOrderStatusId?.toString() || "";
  }

  get selectedCancelOrderStatusId() {
    return this.item.cancelOrderStatusId?.toString() || "";
  }

  get selectedInProgressOrderStatusId() {
    return this.item.inProgressOrderStatusId?.toString() || "";
  }

  get selectedCompleteOrderStatusId() {
    return this.item.completeOrderStatusId?.toString() || "";
  }

  get selectedDiscardedOrderStatusId(){
    return this.item.discardedOrderStatusId?.toString() || "";
  }

  get selectedApprovedOrderStatusId(){
    return this.item.approvedOrderStatusId?.toString() || "";
  }

  get selectedShippedOrderStatusId(){
    return this.item.shippedOrderStatusId?.toString() || "";
  }

  get selectedPendingOrderStatusId(){
    return this.item.pendingOrderStatusId?.toString() || "";
  }

  async updateLogo() {
    const formData = new FormData();
    formData.append("logo", this.file.file);
    formData.append("id", this.item.id);

    const [data, errors] = await easync(this.updateSourceFormData(this.item.id, formData, updateItemTag));
    if (errors) {
      showErrorToast("Errore nell'aggiornamento del negozio");
      return;
    }

    sourceStore.dispatchSetCurrentSource(data);
    showToast("Negozio aggiornato!");
  }

  get logoUrl() {
    return this.file?.url || this.item.logoUrl;
  }

  get sourceType() {
    if (!this.item) {
      return "-";
    }
    if (this.item.constructor.name.toLowerCase().includes("woocommerce")) {
      return "Woocommerce";
    }else{
      return "-"
    }
  }
}
