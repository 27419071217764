

























































































































import { Order } from "@/models";
import OrderLines from "@/components/OrderLine/List.vue";
import StepBar from "@/components/StepBar.vue";
import {  Component, Prop, Mixins } from "vue-property-decorator";
import OrdersMixin, {OrderTags} from "@/mixins/http/OrdersMixin";
import { StepItem } from "../StepBar.vue";
import { ShippingContract } from "@/models";
import { showErrorToast, showToast } from "@/utils/Toast";
import { RequestData } from "@/store/types/RequestStore";
import { easync } from "@/utils/http";
import { RegisterHttp } from "@/utils/Decorators";
import { httpStore } from "../../store/typed";

const reimportTag = "reimport_order";

@Component({ components: { StepBar, OrderLines } })
export default class OrderListItem extends Mixins(OrdersMixin) {
  @Prop() order!: Order;

  private item: Order = this.order;

  private open = false;

  @RegisterHttp(reimportTag) request!: RequestData;

  copied() {
    showToast("Tracking copiato!");
  }

  onOrderChanged(order: Order){
    this.item = order;

  }

  get steps(): StepItem[] {
    return [
      {
        lowerLabel: "Importazione",
        status: this.importStatusValue,
        icon: "import",
      },
      {
        lowerLabel: "Approvazione",
        status: this.approvedStatusValue,
        icon: "thumbs-up",
      },
      {
        lowerLabel: "Stampa",
        status: this.printStatusValue,
        icon: "print",
      },
      {
        lowerLabel: "Spedizione",
        status: this.shipmentStatusValue,
        icon: "truck",
      },
    ];
  }

  statusValue(status: string, completeValue: string) {
    return status == completeValue ? "complete" : "disabled";
  }

   get whatsappUrl() {
      if (this.order.billing.phone) {
        let url = "https://wa.me/";

        var phone = this.order.billing.phone ? this.order.billing.phone : null;

        if (!phone.includes("+")) {
          phone = "39" + phone;
        }
        return url + phone.replace("+|-", "").trim();
      }
      return '';
    }

  get statusStyle() {
const color = (this.item.orderStatus as any).color;
    if(color){

      return {
        'border-color': color, color: color};
    } else {
      return {};
    }
  }

  async reimport(){
    const [data, errors] = await easync(this.reimportOrder({id: this.order.id}));
    if (errors) {
      showErrorToast("Si è verificato un errore");
      return;
    }

    this.item = data;
  }

  get tag() {
    return OrderTags.Reimport+this.item.id;
  }

  get reimporting(){
    return httpStore.status[this.tag]?.loading
  }

  get phoneNumber() {
    return this.order.billing.phone;
  }

  get importStatusValue() {
    return this.statusValue(this.item.importStatus, "imported");
  }

  get printStatusValue() {
    return this.statusValue(this.item.printStatus, "printed");
  }

  get shipmentStatusValue() {
    return this.statusValue(this.item.shipmentStatus, "shipped");
  }

  get approvedStatusValue() {
    return this.statusValue(this.item.approvedStatus, "approved");
  }

  carrierLogo(item: ShippingContract) {
    const carrier = this.carrier(item);
    return require(`@/assets/images/${carrier.code}.png`);
  }

  carrierName(item: ShippingContract) {
    return this.carrier(item)?.name;
  }

  carrier(item: ShippingContract) {
    return ShippingContract.carriers.find((o) => item?.type === o.id);
  }

  onApprovedChanged(approved: string) {
    this.item.approvedStatus = approved ? "approved" : "to_approve";
  }

  onImportedChanged(imported: boolean) {
      this.item.importStatus = imported ? "imported" : "to_import";
  }
}
