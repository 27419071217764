
















import { Source } from "@/models";
import { Component,  Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CouponsMixin from "@/mixins/http/CouponsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import RoundThumb from "@/components/RoundThumb.vue";
import Preview from "@/components/Order/Preview.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import { Coupon } from "@/models";
import { showErrorToast, showToast } from "@/utils/Toast";
import SourceData from "@/components/Coupon/SourceData.vue";
import { easync } from "@/utils/http";

const getItemTag = "get_coupon";
const updateItemTag = "update_coupon";

@Component({
  components: { RoundThumb, Preview, SourceData, PreviewModal },
})
export default class CouponShow extends Mixins(CouponsMixin) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(updateItemTag) updateItemRequest: RequestData;

  item: Coupon = null;

  mounted() {
    this.load();
  }

  get id() {
    return parseInt(this.$route.params.id);
  }

  async update(data: any = null) {
    const payload = { id: this.item.id, ...data };

    const [res, errors] = await easync(
      this.updateCoupon(payload, updateItemTag)
    );

    if (errors) {
      showToast("Errore nell'aggiornamento del coupon", { type: "error" });
      return;
    }

    this.item = res;
    showToast("Coupon aggiornato!");
  }

  async load() {
    const [data, errors] = await easync(
      this.getCoupon(this.id, getItemTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.item = data;
  }

  get loading() {
    return this.getItemRequest?.loading;
  }

  get meta() {
    return this.getItemRequest?.meta;
  }
}
