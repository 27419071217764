
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';
const BASE_CUSTOMER_URL = BASE_API_URL + "customers"

export enum CustomerTags {
  AddToContacts = "AddToContacts",
  RemoveFromContacts = "RemoveFromContacts",
}

@Component
export default class CustomersMixin extends Vue {

  getCustomers(data: any, tag: string) {
    return this.$httpGetQuery(BASE_CUSTOMER_URL, tag, data);
  }

  getCustomer(id: number, tag: string) {
    return this.$httpGet(BASE_CUSTOMER_URL + "/" + id, tag);
  }

  destroyCustomer(id: string | number, tag: string) {
    return this.$httpDelete(BASE_CUSTOMER_URL + "/" + id, tag);
  }

  createCustomer(data: any, tag: string) {
    return this.$httpPost(BASE_CUSTOMER_URL, tag, data);
  }

  updateCustomer(data: any, tag: string) {
    return this.$httpPut(BASE_CUSTOMER_URL + "/" + data.id, tag, data);
  }

  addCustomerToContacts(id: any,) {
    return this.$httpPost(BASE_CUSTOMER_URL + "/" + id + "/add_to_contacts", CustomerTags.AddToContacts, {id});
  }

  removeCustomerFromContacts(id: any,) {
    return this.$httpPost(BASE_CUSTOMER_URL + "/" + id + "/remove_from_contacts", CustomerTags.RemoveFromContacts, {id});
  }
}
