





















import { Component, Prop, Vue } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";

@Component({
  components: {
    VModal
  }
})
export default class VImageModal extends Vue {
  modal!: VModal;
  url = "";

  confirmed!: any | null;
  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.confirmed) {
      this.confirmed();
    }

    this.confirmed = null;
    this.modal.hide();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  preview(url: string) {
    this.modal.show();

    if (url) {
      this.url = url;
    }
  }
}
