






import VPageContentSticky from "@/components/VPageContentSticky.vue";

import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    VPageContentSticky
  }
})
export default class OrderDashboard extends Vue {}
