






















import { Order } from "@/models";
import {  Component, Prop, Mixins } from "vue-property-decorator";
import { Coupon } from "../../models";
import {easync} from "@/utils/http";
import OrdersMixin, {OrderTags} from "@/mixins/http/OrdersMixin";
import JsonViewer from 'vue-json-viewer'
import { httpStore } from "../../store/typed";


@Component({ components: {JsonViewer} })
export default class Coupons extends Mixins(OrdersMixin) {
  @Prop({ required: true }) readonly value!: Order;

  private response: any = null;

  get coupons(): Array<Coupon> {
    return this.value.coupons;
  }

  get loading() {
    return httpStore.status[this.tag]?.loading;
  }

  get tag() {
    return OrderTags.Proxy+this.value.id;
  }

  async performRequest() {
    const [data, errors] = await easync(this.proxyResource(this.value.id));

    if(data) {
      this.response = data;
    }
  }

}
