











import { Component, Prop, Vue} from "vue-property-decorator";
import ProductWarehouseItemList from "@/components/ProductWarehouseItem/List.vue"
import { Product } from "@/models";

@Component({components:{
  ProductWarehouseItemList
}})
export default class ProductWarehouseItemsData extends Vue {
  @Prop({ required: true }) readonly item!: Product;
}
