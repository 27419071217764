













































































import { ProductsMixinTags } from "@/mixins/http/ProductsMixin";
import { WoocommerceProduct, Product } from "@/models";
import { RequestData } from "@/store/types/RequestStore";
import { RegisterHttp } from "@/utils/Decorators";
import Throttler from "@/utils/Throttler";
import { Carousel, Slide } from "vue-carousel";
import { Vue, Component, Prop } from "vue-property-decorator";

import WithErrors from '@/directives/WithErrors';

@Component({
  components: {
    Carousel,
    Slide
  },
  directives: {
    WithErrors
  }
})
export default class ProductSourceData extends Vue {
  @Prop() readonly value: Product;
  private item: Product | null = null;

  private throttler: Throttler = new Throttler();

  @RegisterHttp(ProductsMixinTags.UpdateProduct) updateItemRequest: RequestData;

  created() {
    this.item = this.value ? Object.assign({}, this.value) : null;
  }

  get updateTag() {
    return ProductsMixinTags.UpdateProduct;
  }

  get sourceType() {
    if (!this.item) {
      return "";
    }
    if (this.item.constructor.name.toLowerCase().includes("woocommerce")) {
      return "Woocommerce";
    }else {
      return "";
    }
  }

  private onB2bUpdated() {
    this.throttler.run(() => {
      this.$emit('updated', { b2b: this.item.b2b });
    })
  }
}
