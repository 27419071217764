
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';
const BASE_COUPON_URL = BASE_API_URL+"coupons"

@Component
export default class CouponsMixin extends Vue {

  getCoupons(data: any, tag: string) {
    return this.$httpGetQuery( BASE_COUPON_URL, tag,data);
  }

  getCoupon(id: number, tag: string) {
    return this.$httpGet( BASE_COUPON_URL+"/"+id, tag);
  }

  destroyCoupon(id: string|number, tag: string) {
    return this.$httpDelete( BASE_COUPON_URL+"/"+id, tag);
  }

  createCoupon(data: any, tag: string) {
    return this.$httpPost( BASE_COUPON_URL, tag, data);
  }

  updateCoupon(data: any, tag: string) {
     return this.$httpPut( BASE_COUPON_URL+"/"+data.id, tag, data);
  }
}
