
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_PRODUCT_LABEL_URL = BASE_API_URL+"product_labels"

@Component
export default class ProductLabelsMixin extends Vue {

  getProductLabels(data: any, tag: string) {
    return this.$httpGetQuery(BASE_PRODUCT_LABEL_URL, tag,data);
  }

  destroyProductLabel(id: string|number, tag: string) {
    return this.$httpDelete(BASE_PRODUCT_LABEL_URL+"/"+id, tag);
  }

  createProductLabel(data: any, tag: string) {
    return this.$httpPost(BASE_PRODUCT_LABEL_URL, tag, data);
  }

  updateProductLabel(id: string, data: any, tag: string) {
    return this.$httpPut(BASE_PRODUCT_LABEL_URL+"/"+id, tag, data);
  }
}
