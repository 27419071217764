import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";

const BASE_SHIPMENTS_URL = BASE_API_URL+"shipments"

export enum ShipmentsTags {
  TrackAll = "ShipmentTrackAll",
}

@Component
export default class ShipmentsMixin extends Vue {
  getShipments(data: any, tag: string) {
    return this.$httpGetQuery(
      BASE_SHIPMENTS_URL,
      tag,
      data
    );
  }

  getShipment(id: number, tag: string) {
    return this.$httpGet(
      BASE_SHIPMENTS_URL+"/"+id,
      tag
    );
  }

  trackAllShipments(sourceId: number) {
    return this.$httpPost(
      BASE_SHIPMENTS_URL+"/track_all",
      ShipmentsTags.TrackAll,
      {sourceId}
    );
  }
}
