























import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import ProductLabelCreate from "@/components/ProductLabel/Create.vue";
import ProductLabelItem from "@/components/ProductLabel/GridItem.vue";
import { ProductLabel, Product } from "@/models";
import VImageModal from "@/components/VImageModal.vue";
import { ProductLabelMetadata } from "@/models/ProductLabel";

@Component({
  components: {
    ProductLabelItem,
    ProductLabelCreate,
    VImageModal
  }
})
export default class ProductLabelGrid extends Vue {
  @Prop({ required: true }) readonly item!: Product;
  @Ref() readonly imageModal!: VImageModal;
  onCreated(printLine: ProductLabel) {
    this.$emit("created", printLine);
  }

  onPreview(url: string) {
    this.imageModal.preview(url);
  }

  onDestroyed(printLine: ProductLabel) {
    this.$emit("destroyed", printLine);
  }

  get label() {
    return {
      id: this.item.id,
      metadata: new ProductLabelMetadata(),
      productId: this.item.id,
      quantity: 1
    };
  }

  onUpdated(printLine: ProductLabel) {
    this.$emit("updated", printLine);
  }
}
