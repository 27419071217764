






import { Vue, Component } from "vue-property-decorator";
import IncompleteProducts from "@/components/Product/IncompleteList.vue";

@Component({ components: { IncompleteProducts } })
export default class DiscountDashboard extends Vue {}
