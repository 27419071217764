





















import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import FileUploader from "@/components/FileUploader.vue";
import ProductsMixin from "@/mixins/http/ProductsMixin";
import { showErrorToast, showToast } from "@/utils/Toast";
import { Product } from "@/models";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { easync } from "@/utils/http";

const tag = "upload_cut_file";

@Component({
  components: {
    FileUploader
  }
})
export default class CutFileUpload extends Mixins(ProductsMixin) {
  private file: any = null;

  @RegisterHttp(tag) readonly uploadRequest!: RequestData;

  @Prop({ required: true }) readonly product!: Product;

  onFileChanged() {
    this.upload();
  }

  get loading() {
    return this.uploadRequest?.loading;
  }

  get url() {
    return this.file?.url || this.product?.cut;
  }

  async upload() {
    let formData = new FormData();

    this.$nextTick(async () => {
      formData.append("cut", this.file.file);

      const [data, errors] = await easync(this.updateProductMultipart(this.product.id, formData, tag));
      if (errors) {
        showErrorToast();
        return;
      }

      showToast("File di taglio caricato!");
      this.$emit("changed", data);
    });
  }
}
