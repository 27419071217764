








































































import { Source } from "@/models";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import OrderStatusesMixin from "@/mixins/http/OrderStatusesMixin";
import SourcesMixin from "@/mixins/http/SourcesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import Preview from "@/components/Order/Preview.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import { Webhook } from "@/models";
import VCheckbox from "@/components/VCheckbox.vue";
import Checkbox from "@/components/Checkbox.vue";
import OrderStatus from "@/models/OrderStatus";
import { showErrorToast, showToast } from "@/utils/Toast";
import { User } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const getTag = "get_order_statuses";
const destroyTag = "destroy_order_statuses";
const updateTag = "update_order_status";
const syncTag = "sync_order_statuses";

@Component({
  components: {
    Checkbox,
    VCheckbox,
    VPageContentSticky,
    DataTable,
    RoundThumb,
    Preview,
    PreviewModal,
  },
})
export default class OrderStatusList extends Mixins(
  OrderStatusesMixin,
  SourcesMixin
) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @RegisterHttp(updateTag) updateRequest: RequestData;
  @RegisterHttp(syncTag) syncRequest: RequestData;

  @Getter currentUser!: User;

  items: OrderStatus[] = [];
  page = 1;

  created() {
    this.subscribe();
  }

  get columns() {
    const columns = [
      { name: "name", label: "Nome" },
      { name: "code", label: "Codice" },
    ];
    if(!this.currentUser?.isCollaborator){
      columns.push({ name: "cancelable", label: "Cancellabile" });
    }
    columns.push({ name: "color", label: "Colore" });

    return columns;
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async syncOrderStatuses() {
    const [data, errors] = await easync(
      this.syncSourceOrderStatuses(sourceStore.currentSource.id, syncTag)
    );

    if (errors) {
      showToast(
        "Si è verificato un errore durante la sincronizzazione: " + errors,
        { type: "error" }
      );
      return;
    }

    showToast("Stati sincronizzati!");
    this.items = data;
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source !== sourceStore.currentSource) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  async updateItem(data: any) {
    const response = await this.updateOrderStatus(data, updateTag);
  }

  onUpdated(key: string, value: any, id: string) {
    const data: any = { id };
    data[key] = value;
    this.updateItem(data);
  }

  async loadItems() {
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id,
      },
    };

    const [res, errors] = await easync(
      this.getOrderStatuses(data, getTag)
    );

    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...res);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get syncLoading() {
    return this.syncRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
