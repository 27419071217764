









































import { Order, Source } from "@/models";
import { Vue, Component, Prop, Mixins, Watch, Ref } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import ProductsMixin, { ProductsMixinTags } from "@/mixins/http/ProductsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import RoundThumb from "@/components/RoundThumb.vue";
import Preview from "@/components/Order/Preview.vue";
import PreviewModal from "@/components/Order/PreviewModal.vue";
import { Product } from "@/models";
import WoocommerceProduct from "@/models/woocommerce/Product";
import { showErrorToast, showToast } from "@/utils/Toast";
import SourceData from "@/components/Product/SourceData.vue";
import WarehouseData from "@/components/Product/WarehouseData.vue";
import PrintData from "@/components/Product/PrintData.vue";
import ConfirmModal from '@/components/ConfirmModal.vue';
import { User } from "@/models";
import { easync } from "@/utils/http";

const getItemTag = "get_product";
const destroyItemTag = "destroy_product";

@Component({
  components: {
    RoundThumb,
    SourceData,
    PrintData,
    WarehouseData,
    Preview,
    PreviewModal,
    ConfirmModal
  }
})
export default class ProductShow extends Mixins(ProductsMixin) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(ProductsMixinTags.UpdateProduct) updateItemRequest: RequestData;
  @RegisterHttp(destroyItemTag) deleteRequest: RequestData;
  @Getter currentUser: User;

  @Ref() readonly confirmModal!: ConfirmModal;

  item: Product = null;

  mounted() {
    this.load();
  }

  get id() {
    return parseInt(this.$route.params.id);
  }

  async update(data: any = null) {
    const payload = { id: this.item.id, ...data };

    const [res, errors] = await easync(
      this.updateProduct(payload, ProductsMixinTags.UpdateProduct)
    );

    if (errors) {
      showToast("Errore nell'aggiornamento del prodotto", { type: "error" });
      return;
    }

    this.item = res;
    showToast("Prodotto aggiornato!");
  }

  async load() {
    const [res, errors] = await easync(
      this.getProduct(this.id, getItemTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.item = res;
  }

  private async onDeleteRequest() {
    const message = "Sei sicuro di voler eliminare questo prodotto?";
    const confirmed = await (this.confirmModal as any).confirm(message);

    if (confirmed) {
      this.deleteProduct();
    }
  }

  private async deleteProduct() {
    const [data, errors] = await easync(this.destroyProduct(this.item.id, destroyItemTag));

    if (errors) {
      showErrorToast(this.destroyErrors.message, { duration: 3000 });
      return;
    }

    showToast('Cancellazione avvenuta con successo!');
    this.$router.push({ name: 'products_list' });
  }

  get loading() {
    return this.getItemRequest?.loading;
  }

  get meta() {
    return this.getItemRequest?.meta;
  }

  get destroyErrors() {
    return this.deleteRequest.errors;
  }
}
