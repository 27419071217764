















import { Vue, Component, Prop } from "vue-property-decorator";
import InlineMultiSelect from "@/components/InlineMultiSelect.vue";
import { OrderStatus } from "@/models";
import { ORDER_TYPES } from '@/models/Order';
import { IMultiSelectOption } from "../MultiSelect.vue";

@Component({ components: { InlineMultiSelect } })
export default class OrderTypeMultiSelect extends Vue {
  @Prop() idSelected!: string | number;
  @Prop({ default: (): any[] => [] }) value: any[] | null;
  @Prop() params!: any;
  @Prop() formClass: string;
  @Prop() classes: string;
  @Prop() placeholder?: string;
  @Prop({ required: false }) queryKey: string;
  @Prop({ default: true }) preselect?: boolean;
  @Prop() type: string;

  private items: IMultiSelectOption[] = [];
  private id: any = this.idSelected || null;

  get queryParams() {
    return { ...this.params };
  }

  mounted() {
    this.assignItems();
  }

  private assignItems() {
    this.items = ORDER_TYPES.map(el => {
      return { id: el.value, name: el.label };
    })
  }

  onDataUpdated(data: string[]) {
    this.$emit("change", data);
  }

  onItemsReady() {
    if (this.idSelected) {
      this.id = this.idSelected;
    } else {
      if (this.preselect) {
        this.id =
          this.queryKey && this.$route.query[this.queryKey]
            ? this.$route.query[this.queryKey]
            : this.items[0]?.id;
      }
    }
  }
}
