









import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class HeaderCard extends Vue {
  @Prop() readonly label!: string;
  @Prop() readonly count!: number;
  @Prop() readonly name!: string;

  onClick(){
    this.$router.push({name: `${this.name}_list`});
  }

  get formattedLabel() {
    switch (this.label) {
      case "customers":
        return "Clienti";
      case "products":
        return "Prodotti";
      case "orders":
        return "Ordini";
      case "coupons":
        return "Coupon";
      default:
        return "";
    }
  }
}
