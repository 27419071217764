



































































import { Source } from "@/models";
import SourcesMixin from "@/mixins/http/SourcesMixin";
import StoreSubscriber from "@/store/StoreSubscriber";
import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import HeaderCard from "@/components/Source/HeaderCard.vue";
import { showErrorToast, showToast } from "@/utils/Toast";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";
const tag = "get_source";
const syncTag = "sync_source";

export interface TabItem {
  name: string;
  label: string;
  disabled?: boolean;
}
@Component({
  components: {
    HeaderCard
  }
})
export default class WarehouseItemDashboard extends Mixins(SourcesMixin) {
  @RegisterHttp(tag) request: RequestData;
  @RegisterHttp(syncTag) sourceItemRequest: RequestData;

  tabs = [
    { name: "source_webhooks", label: "Webhooks" },
    { name: "source_statuses", label: "Stati" },
    { name: "source_shipping_methods", label: "Metodi di spedizione" }
  ];

  private item: Source = null;

  mounted() {
    this.$router.push({ name: "source_webhooks" });
    this.loadSource();
    this.subscribe();
  }

  get source() {
    return sourceStore.currentSource;
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    this.loadSource()
  }

  get stats() {
    return {orders: this.item?.ordersCount||0, products: this.item?.productsCount||0, coupons: this.item?.couponsCount||0, customers: this.item?.customersCount||0}
  }

  async loadSource() {
    const [data, errors] = await easync(this.getSource(this.source.id, tag))

    if(data){
      this.item = data;
    }
  }

  get syncing() {
    return this.sourceItemRequest?.loading;
  }

  async sync() {
    const [res, errors] = await easync(
      this.syncSource(sourceStore.currentSource.id, syncTag)
    );

    if (errors) {
      showErrorToast("Si è verificato un errore durante la sincronizzazione: " + errors);
      return;
    }

    if (res.syncedAt) {
      showToast("Negozio sincronizzato con successo!");
      sourceStore.dispatchSetCurrentSource(res);
    } else {
      showToast("Si è verificato un errore durante la sincronizzazione", {
        type: "error"
      });
    }
  }
}
